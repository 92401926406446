import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { SliceZone } from '@prismicio/react';
import { v4 as uuidv4 } from 'uuid';

import Layout from '@/components/Layout';
import Section from '@/components/Section';
import { Box } from '@mui/material';
import { CustomTypography } from '@/components/Typography/CustomTypography';
import components from '../slices';

const CaseStudyTemplate = ({ data, pageContext }) => {
  const {
    body: slices,
    social_body: socialBody,
    case_study_page_category: splashCategory,
    case_study_page_tag: splashTag,
    case_study_headline: splashHeadline,
    case_study_background_image: splashBackgroundImage,
  } = data.prismicCaseStudyPage.data;

  // html head data
  const headData = useMemo(
    () =>
      socialBody.map(({ primary: { description, image, title } }) => ({
        title: title.text,
        description: description.text,
        ogTitle: title.text,
        ogDescription: description.text,
        ogImageUrl: image.url,
      }))[0],
    [socialBody],
  );

  const allCaseStudies = data.allPrismicCaseStudyPage.nodes;

  return (
    <Layout
      {...headData}
      lang={pageContext.lang}
      altLangs={pageContext.alternate_languages}
    >
      <CaseStudySplash
        splashTag={splashTag}
        splashHeadline={splashHeadline}
        splashBackgroundImage={splashBackgroundImage}
        splashCategory={splashCategory}
      />
      <SliceZone
        slices={slices}
        components={components}
        context={{
          category: splashCategory,
          prev: pageContext.prev,
          next: pageContext.next,
          allCaseStudies,
          lang: data.prismicCaseStudyPage.lang,
        }}
      />
    </Layout>
  );
};

const CaseStudySplash = ({
  splashCategory,
  splashTag,
  splashHeadline,
  splashBackgroundImage,
}) => {
  const { sectionStyle, headLineStyle, gridStyle } = useMemo(() => {
    const baseStyle = {
      sectionStyle: {
        overflow: 'hidden',
        height: 660,
      },
      gridStyle: {
        justifyContent: 'space-between',
        display: `flex`,
        maxWidth: 'xl',
        width: 1,
        mx: 'auto',
      },
      headLineStyle: {
        display: `flex`,
        flexDirection: `column`,
        width: 1,
      },
    };
    return {
      sectionStyle: {
        ...baseStyle.sectionStyle,
        backgroundImage:
          splashBackgroundImage &&
          splashBackgroundImage.url &&
          `url("${splashBackgroundImage.url}")`,
        backgroundPosition: `center`,
        backgroundSize: `cover`,
        pb: { xs: 6, sm: 8 },
        px: { xs: 3, sm: 4, lg: 13.5 },
      },
      gridStyle: {
        ...baseStyle.gridStyle,
        flexDirection: `column-reverse`,
        alignItems: `center`,
      },
      headLineStyle: {
        ...baseStyle.headLineStyle,
        textAlign: `center`,
        gap: { xs: 1, sm: 2 },
        color: `#ffffff`,
        maxWidth: 800,
      },
    };
  });
  return (
    <Section
      sx={{
        ...sectionStyle,
      }}
    >
      <Box
        sx={{
          ...gridStyle,
        }}
      >
        <Box sx={{ ...headLineStyle }}>
          {splashTag.richText.map(({ text, type }) => (
            <CustomTypography
              key={uuidv4()}
              variant={type}
              customVariant="heading6"
              sx={{
                display: `flex`,
                justifyContent: `center`,
                color: `#FFFFFF`,
                mb: { xs: 1, sm: 0 },
              }}
            >
              {text} • {splashCategory}
            </CustomTypography>
          ))}
          {splashHeadline.richText.map(({ text, type }) => (
            <CustomTypography
              key={uuidv4()}
              customVariant="heading1"
              variant={type}
            >
              {text}
            </CustomTypography>
          ))}
        </Box>
      </Box>
    </Section>
  );
};

export const query = graphql`
  query CaseStudyQuery($lang: String, $uid: String) {
    prismicCaseStudyPage(lang: { eq: $lang }, uid: { eq: $uid }) {
      _previewable
      lang
      alternate_languages {
        uid
        type
        lang
      }
      data {
        case_study_page_category
        case_study_background_image {
          url
        }
        case_study_headline {
          richText
        }
        case_study_page_tag {
          richText
        }
        case_study_description {
          richText
        }
        social_body {
          ... on PrismicCaseStudyPageDataSocialBodyGeneralCard {
            primary {
              description {
                text
              }
              title {
                text
              }
              image {
                url
              }
            }
          }
          ... on PrismicCaseStudyPageDataSocialBodyTwitterCard {
            primary {
              description {
                text
              }
              title {
                text
              }
              image {
                url
              }
            }
          }
        }
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...CaseStudyContentSpot
          ...CaseStudyDescription
          ...CaseStudyQuote
          ...CaseStudyInfo
          ...CaseStudyRichText
          ...CaseStudyRelatedPosts
          ...CaseStudyPipedriveForm
          ...CaseStudyCta
        }
      }
    }
    allPrismicCaseStudyPage(
      filter: { lang: { eq: $lang } }
      sort: { order: DESC, fields: first_publication_date }
    ) {
      nodes {
        url
        id
        data {
          case_study_headline {
            richText
          }
          case_study_description {
            richText
          }
          case_study_background_image {
            alt
            gatsbyImageData(placeholder: BLURRED)
          }
          case_study_page_tag {
            text
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(CaseStudyTemplate);
